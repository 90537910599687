/* Reset and Base Styles
----------------------------------------------- */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-weight: 300;
  vertical-align: baseline;
  outline: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  overflow: hidden;
}

/* Global Styles
----------------------------------------------- */
html, body {
  height: 100%;
}

body {
  margin: 0;
  width: 1920px;
  height: 1080px;
  font-family: "Hiragino Kaku Gothic JIS2004", sans-serif;
  font-weight: 200;
  line-height: 1.15em;
  color: #fcfcfc;
  background-color: #F1EEE6;
  background-color: #F1EEE6;
}

.mat-dialog-container {
  padding: 0 !important;
}

button {
  padding: 0;
}

/* Footer Styles
----------------------------------------------- */
.footer {
  position: absolute;
  top: 975px;
  width: 1920px;
  height: 105px;
  // clip-path: polygon(0 0px, 215px 0px, 270px 31px, 1920px 31px, 1920px 151px, 0 151px);
  // background-color: #F1EEE6;
}

.cart_icon_box {
  position: fixed;
  top: 50px;
  left: 1720px;
}

.num {
  position: relative;
  display: flex;
  width: 175px;
  height: 78px;
}

.num_svg {
  width: 80px;
}

.num_small {
  width: 62px;
  height: 60px;
  margin-right: 10px;
  line-height: 60px;
  font-size: 46px;
  text-align: center;
  color: #F1EEE6;
  font-weight: normal;
}

/* Footer Price Styles
----------------------------------------------- */
.footer_price {
  display: none;
  margin-top: 12px;
}

.footer_price p {
  font-size: 26px;
  line-height: 40px;
  margin: 7px 7px 2px 7px;
}

.footer_price_num {
  width: 160px;
  height: 44px;
  background-color: #8282A2;
  color: #D9D9D9;
}

.footer_price_num p {
  font-size: 32px;
  line-height: 40px;
  margin: 2px 6px 2px 0;
  text-align: right;
}

/* Button Styles
----------------------------------------------- */
.buttons {
  display: flex;
  position: absolute;
  left: 420px;
  z-index: 999;
}

.button01, .button02, .button03, .button05 {
  width: 340px;
  height: 90px;
  margin: 15px 15px 0;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  color: #ddd;
  border-bottom: 0px;
  border-radius: 10px 10px 0 0;
}

.button01 {
  background-color: #B5A472;
  color: #303000;
}

.button02, .button03 {
  background-color: #DCD3B2;
  color: #303000;
}

.button05{
  background-color: #5D579D ;
}

.button_text {
  height: 32px;
  margin-top: 32px;
}

.button01.focus,
.button02.focus,
.button03.focus,
.button05.focus
{
  border: 15px solid #EDCE31;
  margin: 0;
  border-bottom: 0px;
  border-radius: 25px 25px 0 0;
}

/* Debug Window Styles
----------------------------------------------- */
.debug_window {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 200px;
  height: 500px;
  padding: 1em;
  color: #FCFCFC;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.pink {
  background-color: pink;
}

/* Phone Styles
----------------------------------------------- */
.phone {
  position: absolute;
  top: 30px;
  left: 50px;
  font-size: 25px;
  line-height: 1.2em;
  color: #303000;
  display: flex;
  align-items: center;
}

.phone_02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  height: 45px;
  margin-left: 13px;
}

.phone_02 img {
  height: 45px;
}

.phone_02 {
  font-size: 50px;
  line-height: 50px;
}

/* Modal Box Styles
----------------------------------------------- */

.modal_box {
  width: 1536px;
  height: 880px;
  background-color: #fcf7ed;
  border: none;
  color: #303000;
  text-align: center;
}

.modal_top_text{
  font-size: 48px;
  margin-top: 200px;
  line-height: 1.2em;
}

.small_text{
  font-size: 36px;
  margin-bottom: 0px;
}

button {
  background-color: #F2EEE6;
  border: 0;
  outline: none;
}

.select_position {
  margin-top:310px;
}

/* Select */
.select {
  display: flex;
  font-size: 36px;
  justify-content: center;
  line-height: 1em;
  margin-top: 20px;
}

.button04 {
  background-color: #B5A472;
  color: #303000;
  font-size: 36px;
  height: 100px;
  line-height: 1.2em;
  padding: 15px;
  width: 300px;
  border: 10px solid #FCF7ED;
  margin-top: 30px;
}



.button04.focus {
  border: 10px solid #EDCE31;
}

